<style lang="scss">
  .add-network-modal{
    .network-section {
      padding: 20px 0;
      border-bottom: 2px solid #5e50ee;

      .invalid-feedback{
        margin-bottom: 10px;
      }
    }
  }
</style>

<template>
  <div class="add-network-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(addNetwork)">

        <section class="mb-2">
          <section class="network-section">
            <h3>Network Details</h3>

            <div class="network-item">
              <label>Name</label>
              <validation-provider
                v-slot="validationContext"
                name="Name"
                :rules="{ required: true, min: 5 }"
              >
                <b-form-input
                  v-model="network.name"
                  :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter name*"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="network-item">
              <b-form-checkbox v-model="network.auto_connect" name="check-button" switch>
                Auto connect
              </b-form-checkbox>
            </div>

            <div class="network-item">
              <label>Security</label>
              <b-form-select v-model="network.security" class="mb-2">
                <b-form-select-option v-for="option in securityOptions" :key="option" :value="option">{{ option }}</b-form-select-option>
              </b-form-select>
            </div>

            <div class="network-item">
              <label>MAC Address Randomization Mode</label>
              <b-form-select v-model="network.mac_randomization_mode" class="mb-2">
                <b-form-select-option v-for="option in macOptions" :key="option" :value="option">{{ option }}</b-form-select-option>
              </b-form-select>
            </div>

            <div v-if="canShowPassphrase()" class="network-item">
              <label>Passphrase</label>
              <validation-provider
                v-slot="validationContext"
                name="Passphrase"
                :rules="{ required: passphraseRequired(), min: 5 }"
              >
                <b-form-input
                  v-model="network.passphrase"
                  type="password"
                  :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter passphrase*"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </section>

          <section v-if="canShowEAP()" class="network-section">
            <h3>EAP Details</h3>

            <div class="network-item">
              <b-form-checkbox v-model="network.eap_client_cert_type" name="check-button" switch>
                Include Certificates
              </b-form-checkbox>
            </div>

            <div class="network-item">
              <label>Eap Outter</label>

              <validation-provider v-slot="validationContext" name="EAP outter" :rules="{ required: true }">
                <b-form-select v-model="network.eap_outer" :state="getValidationState(validationContext)" class="mb-2">
                  <b-form-select-option v-for="option in eapOutter" :key="option" :value="option">
                    {{ option }}
                  </b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="network-item">
              <label>Eap Inner</label>

              <validation-provider
                v-slot="validationContext"
                name="EAP inner"
                :rules="{ required: eapInnerRequired() }"
              >
                <b-form-select v-model="network.eap_inner" :state="getValidationState(validationContext)" class="mb-2">
                  <b-form-select-option v-for="option in eapInner" :key="option" :value="option">{{ option }}</b-form-select-option>
                </b-form-select>

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="network-item">
              <label>Eap identity</label>
              <validation-provider
                v-slot="validationContext"
                name="EAP identity"
                :rules="{ }"
              >
                <b-form-input v-model="network.eap_identity" :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter eap identity" />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="network-item mb-1">
              <label>Eap Password</label>
              <validation-provider
                v-slot="validationContext"
                name="Eap password"
                :rules="{ }"
              >
                <b-input-group>
                  <b-form-input
                    v-model="network.eap_password"
                    :state="getValidationState(validationContext)" class="mb-0" :type="showPassword ? 'text' : 'password'" placeholder="Enter password*"
                  />

                  <b-input-group-append>
                    <b-button @click="showPassword = !showPassword">
                      <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
                    </b-button>
                  </b-input-group-append>

                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-input-group>

              </validation-provider>
            </div>

            <div class="network-item">
              <b-form-checkbox v-model="network.eap_save_credentials" name="check-button" switch>
                Eap save credentials
              </b-form-checkbox>
            </div>
          </section>

          <section class="network-section">
            <h3>SSID Details</h3>

            <div class="network-item">
              <label>SSID</label>
              <validation-provider
                v-slot="validationContext"
                name="SSID"
                :rules="{ required: true }"
              >
                <b-form-input v-model="network.ssid" :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter ssid*" />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="network-item">
              <b-form-checkbox v-model="network.ssid_hidden" name="check-button" switch>
                SSID hidden
              </b-form-checkbox>
            </div>
          </section>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button type="submit" variant="primary">Add new network</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import NetworkService from '../../../services/NetworkService'

  export default {
    components: {},
    props: {},
    data() {
      return {
        showPassword: false,
        network: {
          name: '',
          auto_connect: true,
          eap_client_cert_type: false,
          eap_password: '',
          eap_identity: '',
          eap_inner: 'Automatic',
          eap_outer: '',
          eap_save_credentials: false,
          passphrase: '',
          security: 'None',
          ssid: '',
          ssid_hidden: false,
          mac_randomization_mode: 'Hardware'
        },
        securityOptions: [
          'WEP-PSK',
          'WEP-8021X',
          'WPA-PSK',
          'WPA-EAP',
        ],
        eapInner: [
          'Automatic',
          'MSCHAPv2',
          'PAP',
          'CHAP',
          'GTC',
        ],
        eapOutter: [
          'EAP-AKA',
          'EAP-TLS',
          'EAP-TTLS',
          'EAP-SIM',
          'PEAP',
        ],
        macOptions: [
          'Hardware',
          'Automatic',
        ],
      };
    },
    mounted() {},
    methods: {
      addNetwork() {
        NetworkService.createNetwork(this.network).then(() => {
          this.$toast.success(`Added network ${this.network.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not add network, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      eapInnerRequired() {
        const o = this.network.eap_outer;
        return o !== 'EAP-FAST' && o !== 'EAP-TTLS' && o !== 'PEAP';
      },
      passphraseRequired() {
        const s = this.network.security;
        return s === 'WEP-PSK' || s === 'WPA-PSK';
      },
      canShowPassphrase() {
        const s = this.network.security;
        return s !== 'None' && s !== 'WEP-8021X' && s !== 'WPA-EAP';
      },
      canShowEAP() {
        const s = this.network.security;
        return s === 'WEP-8021X' || s === 'WPA-EAP';
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    },
  };
</script>
